// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require jquery
//= require popper
//= require bootstrap

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("chartkick")
require("chart.js")

import JQuery from 'jquery';
window.$ = window.JQuery = window.jQuery = JQuery;

// added via npm
require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")
import Select2 from "select2"

import LocalTime from 'local-time';
LocalTime.start();

import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap";

// Stimulus Controllers
import "controllers"

$(document).on('turbolinks:load', function() {
  $("table[role='datatable']").each(function() {
    $(this).DataTable()
  });

  $(document).on('submit', '.password-form, .edit-user-form', function(e) {
    e.preventDefault();
    var form = $(this);
    var data = form.serialize();

    $.ajax({
      type: "PUT",
      url: form.attr('action'),
      data: data,
      success: function(response) {
        $('#user-modal').modal('hide');
        setTimeout(function() {
          var searchParam = getSearchParamFromURL('search');
          var pageParam = getSearchParamFromURL('page');
          var url = '/admin/users?';
          if (searchParam) {
            url += 'search=' + encodeURIComponent(searchParam);
          }
          if (pageParam) {
            url += '&page=' + encodeURIComponent(pageParam);
          }
          window.location.href = url;
        }, 500);
      },
      error: function() {
        alert('Error updating password');
      }
    });
  });

  function getSearchParamFromURL(paramName) {
    var urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(paramName);
  }
});


const current_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
localStorage.setItem('timezone', current_timezone);

$.ajax({
  type: 'POST',
  url: '/set_timezone',
  data: { timezone: current_timezone },
  success: function(response) {
    // Handle the response from the controller if needed
  },
  error: function(error) {
    // Handle any errors that occur during the AJAX request
  }
});


if(window.location.pathname != "/admin/events/map" && localStorage.getItem("lat") != null){
  localStorage.removeItem("lat");
  localStorage.removeItem("lng");
  localStorage.removeItem("map_zoom");
}

document.addEventListener('turbolinks:load', () => {
  document.addEventListener('ajax:success', (event) => {
    const [data, status, xhr] = event.detail;
    const element = event.target;

    if (element.matches('form[data-remote=true]')) {
      if (element.classList.contains('link-event-type-form')) {
        $('#event-type-link-modal').modal('hide');
      } else {
        $('#event-type-modal').modal('hide');
      }
    }
  });
  document.addEventListener('ajax:error', (event) => {
    const [data, status, xhr] = event.detail;
    const element = event.target;

    if (element.matches('form[data-remote=true]')) {
      console.error('An error occurred during AJAX request.');
    }
  });
});