import consumer from "./consumer"

consumer.subscriptions.create("ProdRtsp1Channel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    var anpr = document.getElementById('prod_rtsp_1');
    var elm = document.createElement('tr');
    var td = document.createElement('td');
    var img = document.createElement('img');
    img.src = data.lp_image;
    img.className='zoom';
    img.setAttribute("style", "height: 64px;");
    console.log(img,'img')
    elm.innerHTML +=
      "<td>" + data.id +"</td>" + "<td>" + data.captured_on +"</td>" + "<td>" + data.camera_name +"</td>" + "<td><b>" + data.lp_number +"</b></td>";
    td.append(img)
    elm.append(td);
    anpr.prepend(elm);
  }
});
